<template>
  <base-popup
    :title="item ? titles[item.Id - 1] : ''"
    :icon="UtilGetIcon('view-CheckLists')"
    @save="save"
    v-model="dialog"
    v-bind="$attrs"
  >
    <v-container v-if="item">
      <v-text-field
        type="number"
        :value="item.MonitoringCount"
        @change="item.MonitoringCount = $event"
        :label="val1[item.Id - 1]"
      ></v-text-field>
      <v-text-field
        type="number"
        :value="item.ViolationCount"
        @change="item.ViolationCount = $event"
        :label="val2[item.Id - 1]"
      ></v-text-field>
      <v-textarea
        :value="item.ViolationsText"
        @change="item.ViolationsText = $event"
        :label="val3[item.Id - 1]"
      ></v-textarea>
      <v-textarea
        v-if="item.Id === 1 || item.Id === 4"
        :value="item.TasksText"
        @change="item.TasksText = $event"
        label="Перечень корректирующих мер"
      ></v-textarea>
      <v-textarea
        :value="item.ExecutionText"
        @change="item.ExecutionText = $event"
        label="Сведения о выполнении"
      ></v-textarea>
    </v-container>
  </base-popup>
</template>
<script>
import BasePopup from "@/layouts/BasePopup.vue";

export default {
  name: "consolidated-report-menu",
  components: { BasePopup },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      dialog: false,
      titles: [
        "Мероприятия по мониторингу наличия лекарственных препаратов и медизделий",
        "Побочные действия, нежелательные реакции при применении лекарственных препаратов",
        "Побочные действия, нежелательные реакции при применении и  эксплуатации медицинских изделий",
        "Мониторинг наличие у медицинских работников документов об образовании",
      ],
      val1: [
        "Количество мониторингов",
        "Количество случаев",
        "Количество случаев",
        "Количество мониторингов",
      ],
      val2: [
        "Количество фактов несоответствия обеспечения лекарственными препаратами и медизделиями",
        "Подготовлены и переданы извещения в ТО РЗН (количество)",
        "Подготовлены и переданы извещения в ТО РЗН (количество)",
        "Количество фактов несоответствия",
      ],
      val3: [
        "Выявленные несоответствия",
        "Краткий анализ информации о побочных действиях и непредвиденных нежелательных реакциях при применении ЛП",
        "Краткий анализ информации о побочных действия и непредвиденных нежелательных реакцииях при применении и эксплуатации МИ",
        "Выявленные несоответствия",
      ],
    };
  },
  watch: {
    item(val) {
      if (val) {
        this.dialog = true;
      }
    },
    dialog(val) {
      if (!val && this.item) {
        this.$emit("update:item", null);
      }
    },
  },
  methods: {
    save() {
      this.$emit("save");

      this.dialog = false;
    },
  },
};
</script>
